.assigned-input {
  display: flex;
  flex-flow: row wrap;
  align-items: normal;
  &__assignees {
    display: flex;
    align-items: flex-end;
    justify-content: right;
    flex-grow: 1;
  }
  &__assignee {
    z-index: 1;
    background-color: #fff;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 5px;
  }
  &__add {
    display: flex;
    align-items: flex-end;
    justify-content: right;
  }
}
