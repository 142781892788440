.post-table {
  table-layout: fixed;
  width: 100%;
  tbody .post-table__tr {
    cursor: pointer;
  }
  &__th {
    &Label {
      font-size: 14px;
      font-weight: bold;
      display: inline-block;
      vertical-align: top;
      white-space: pre;
    }
    &Sort {
      padding: 0 !important;
      line-height: 14px;
      vertical-align: top;
    }
    &Filter {
      font-size: 12px;
      &Text {
        font-weight: normal;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        color: #adadad;
        &:hover,
        &--active {
          color: $primary;
        }
        &--active {
          font-weight: bold;
        }
      }
    }
  }
  &__author {
    width: 10%;
  }
  &__preview {
    width: 40%;
    &Title {
      font-weight: bold;
      margin-bottom: 5px;
    }
    &Body {
      padding: 0;
      margin: 0;
    }
    &Bar {
      color: #a7a7a7;
      font-size: 12px;
      &Comments {
        &--commented {
          color: $danger;
        }
      }
      &Item {
        display: inline-block;
        vertical-align: top;
        line-height: 16px;
        margin-top: 5px;
        margin-right: 10px;
      }
      &ViewedBy {
        color: $danger;
        font-weight: bold;
      }
    }
  }
  &__posted {
    width: 20%;
  }
  &__assigned {
    width: 20%;
  }
  &__actions {
    width: 10%;
    .dropdown-menu {
      left: auto !important;
      right: 0 !important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .post-table__author {
    width: 10%;
  }
  .post-table__preview {
    width: 50%;
  }
  .post-table__posted {
    width: 12%;
  }
  .post-table__assigned {
    width: 18%;
  }
  .post-table__actions {
    width: 10%;
  }
}
@media screen and (min-width: 1400px) {
  .post-table__author {
    width: 10%;
  }
  .post-table__preview {
    width: 60%;
  }
  .post-table__posted {
    width: 10%;
  }
  .post-table__assigned {
    width: 15%;
  }
  .post-table__actions {
    width: 5%;
  }
}
@media screen and (min-width: 1600px) {
  .post-table__author {
    width: 10%;
  }
  .post-table__preview {
    width: 65%;
  }
  .post-table__posted {
    width: 10%;
  }
  .post-table__assigned {
    width: 10%;
  }
  .post-table__actions {
    width: 5%;
  }
}
@media screen and (min-width: 1900px) {
  .post-table__author {
    width: 5%;
  }
  .post-table__preview {
    width: 70%;
  }
  .post-table__posted {
    width: 10%;
  }
  .post-table__assigned {
    width: 10%;
  }
  .post-table__actions {
    width: 5%;
  }
}
