.events {
  background-color: #d8d9dd;
  position: relative;
  height: 4000px;
  border-radius: 4px;

  &__toolbar {
    overflow: hidden;
    position: sticky;
    top: 0;
    height: 50px;
    width: 100%;
    background-color: #f3f4f7;
    padding: 4px 0;
    z-index: 999;
  }

  &__head {
    overflow: hidden;
    position: sticky;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    top: 50px;
    height: 50px;
    width: 100%;
    background-color: #5a7ee7;
    color: #fff;
    padding-left: 150px;
    padding-right: 40px;
    z-index: 999;
    &Inner {
      position: relative;
      width: 100%;
      &--docked {
        width: 300px;
      }
    }
  }

  &__milestones {
    overflow: hidden;
    position: sticky;
    top: 100px;
    height: 30px;
    width: 100%;
    background-color: #5373d4;
    color: #fff;
    padding-left: 150px;
    padding-right: 40px;
    z-index: 999;
    &Inner {
      position: relative;
      width: 100%;
      &--docked {
        width: 300px;
      }
    }
  }
  &__milestoneNavItem {
    color: #aabaeb;
    padding: 4px;
    display: inline-block;
    vertical-align: top;
    border-radius: 0;
    &:hover {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.1);
    }
    &--active {
      background-color: rgba(0, 0, 0, 0.1);
    }
    &--notEmpty {
      font-weight: bold !important;
      color: #fff !important;
      background-color: #3b4986 !important;
    }
  }

  &__pageNav {
    padding: 12px 0;
    position: absolute;
    z-index: 10;
    top: 0;
    width: 40px;
    text-align: center;
    cursor: pointer;
    &--prev {
      left: -40px;
    }
    &--next {
      right: -40px;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &__weekNavItem {
    color: #fff;
    font-weight: bold;
    padding: 14px;
    display: inline-block;
    vertical-align: top;
    border-radius: 0;
    &:hover {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.1);
    }
    &--active {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &__body {
    position: absolute;
    top: 130px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
  }
  &__bodyInner {
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    &--docked {
      width: 490px;
      right: auto;
    }
  }
  &__detailsView {
    position: absolute;
    top: 0;
    left: 490px;
    right: 0;
    bottom: 0;
  }
  &__side {
    background-color: #e8e9ec;
    width: 150px;
  }
  &__sideTime {
    text-align: center;
    height: 30px;
    padding: 5px;
    line-height: 20px;
    box-shadow: 0 1px 0 #c5c7cc;
    border-right: 2px solid #b3b5b9;
    &--active {
      background-color: #b3b5b9;
      color: #fff;
    }
  }
  &__grid {
    position: absolute;
    z-index: 4;
    top: 0;
    left: 150px;
    right: 0;
    bottom: 0;
    background-image: -webkit-linear-gradient(0deg, transparent 5em, rgba(255, 0, 0, 0.2) 0, transparent 5.1em),
      -webkit-linear-gradient(#c5c7cc 1px, transparent 0);
    background-image: -moz-linear-gradient(0deg, transparent 5em, rgba(255, 0, 0, 0.2) 0, transparent 5.1em),
      -moz-linear-gradient(#c5c7cc 1px, transparent 0);
    background-image: -o-linear-gradient(0deg, transparent 5em, rgba(255, 0, 0, 0.2) 0, transparent 5.1em),
      -o-linear-gradient(#c5c7cc 1px, transparent 0);
    background-image: -ms-linear-gradient(0deg, transparent 5em, rgba(255, 0, 0, 0.2) 0, transparent 5.1em),
      -ms-linear-gradient(#c5c7cc 1px, transparent 0);
    -webkit-background-size: 100% 30px;
    -moz-background-size: 100% 30px;

    /* In a perfect world... */
    background-image: linear-gradient(0deg, transparent 5em, rgba(255, 0, 0, 0.2) 0, transparent 5.1em),
      linear-gradient(#c5c7cc 1px, transparent 0);
    background-size: 100% 30px;
  }
  &__gridWeek {
    width: 12.5%;
    display: inline-block;
    vertical-align: top;
    box-shadow: inset -1px 0 0 #c5c7cc;
    border-right: 1px solid #c5c7cc;
    pointer-events: none;
  }
  &__addEvent {
    position: absolute;
    top: 3px;
    left: 0;
    color: #fff;
    .events__btntxt {
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      display: inline-block;
      padding-top: 3px;
      padding-left: 5px;
      vertical-align: top;
    }
    &:hover {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  &__highlighter {
    position: absolute;
    top: -200px;
    left: -200px;
    height: 30px;
    width: 100px;
    background-color: rgba(0, 0, 0, 0.05);
    pointer-events: none;
    &--x {
      left: 0;
      right: 0;
      width: auto;
    }
    &--y {
      top: 0;
      bottom: 0;
      width: 100px;
      height: auto;
    }
  }
  .event {
    position: absolute;
    top: 900px;
    left: 0;
    padding-block: 5px;
    z-index: 6;
    &:hover {
      z-index: 99 !important;
      .event__box {
        box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.5);
      }
    }

    &__arrow {
      position: absolute;
      right: -28px;
      top: 25px;
      height: 36px;
      width: 36px;
      stroke: #5a7ee7;
    }
    &--active {
      z-index: 90;
      .event__box {
        border: 3px solid #5a7ee7;
        padding: 7px;
      }
    }
    &__box {
      height: 80px;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      padding: 10px 10px 30px 10px;
      position: relative;
      cursor: pointer;
      &:active {
        transform: translateY(1px);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
        background: #f3f3f3;
      }
    }
    &__boxInner {
      height: 40px;
      overflow: hidden;
    }
    &__name {
      font-weight: bold;
      font-size: 14px;
      margin: 0;
      padding: 0;
      line-height: 1.4;
    }
    &__condition {
      color: red;
    }
    &__description {
      margin-bottom: 5px;
      font-size: 12px;
      line-height: 1.2;
    }
    &__troubleshootingIcon {
      height: 16px;
      width: 16px;
      cursor: pointer;
      margin-right: 5px;
    }
    &__footer {
      position: absolute;
      bottom: 5px;
      left: 10px;
    }
    &__type {
      background-color: #e8e9ec;
      border-radius: 6px;
      padding: 0px 4px;
      font-size: 11px;
    }
    &__states {
      position: absolute;
      top: -20px;
      left: 0;
      height: 20px;
      z-index: 90;
    }
    &__state {
      font-size: 12px;
      background-color: #a3a3a3;
      box-shadow: inset -2px 0 0 #818181;
      color: #fff;
      padding: 2px 10px;
      display: inline-block;
      text-align: left;
      height: 20px;
      white-space: pre;
      text-overflow: ellipsis;
      overflow: hidden;
      &:last-of-type {
        box-shadow: none;
      }
      &--custom {
        background-color: #e0a930;
      }
    }
  }
}

.eventView {
  position: fixed;
  z-index: 100;
  top: 335px;
  left: 500px;
  right: 25px;
  bottom: 0;
  background-color: #fff;
  overflow: auto;
  &__box {
    border-radius: 5px;
    position: absolute;
    top: 5px;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &__form {
    padding: 20px;
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 150;
  }
  &__id {
    font-size: 10px;
    color: #ddd;
  }
}

.programs {
  .users-program-tab {
    > .card {
      background: #f3f4f7;

      > .card-body {
        padding: 0;
        display: flex;

        > .tab-content {
          width: 100%;
          padding-top: 0;
        }

        > .nav-tabs {
          border-bottom: none;
          background: #edeef1;
          padding: 10px;
          border-radius: 6px;
          width: 18%;
          margin-right: 45px;

          > .nav-item {
            cursor: pointer;

            &:not(:last-of-type) {
              margin-bottom: 9px;
            }

            > .nav-link {
              background: #f3f4f7;
              color: #8f8f91;
              &:hover {
                background: #f7f8fa;
              }
            }

            > .active {
              border-bottom: none !important;
              border: #5369f8 1px solid !important;
              border-radius: 0.25rem;
              color: #5369f8;
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}

.program {
  position: relative;

  &--card {
    cursor: pointer;
    border-radius: 2px;
    &:hover {
      box-shadow: 0 0 0 2px #a7b2bb;
    }
    &:active {
      box-shadow: inset 0 0 0 1px #4f6dc9;
    }
  }
  &__description {
    font-size: 12px;
    margin: 0;
    padding: 0;
  }
  &__icon {
    width: 35px;
    height: 35px;
    border-radius: 2px;
    float: left;
    margin-right: 10px;
    margin-bottom: 20px;
    img {
      width: 19px;
      height: 19px;
      margin: 8px;
    }
  }
  &__bgImage {
    opacity: 0.3;
  }
  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 20px;
    padding-left: 20px;
  }
}

.articles {
  &__header {
    position: relative;
  }
  &__toolbar {
    position: absolute;
    top: 0;
    right: 0;

    // Make sure it only applied to CTAWidgets Page
    &.ctaWidgets {
      left: 0;
      display: flex;
      align-items: center;

      .articles__button {
        margin-left: auto;
      }

      .articles__search {
        width: 400px;
      }
    }
  }
  &__search {
    display: inline-block;
    vertical-align: top;
    margin-right: 24px;
    width: 500px;
  }
  &__filterTags {
    display: inline-block;
    min-width: 300px;
    margin-right: 24px;

    .react-select > div {
      background-color: white;
    }
  }
}

.article {
  &--row {
    border-radius: 5px;
    cursor: pointer;
    td {
      background-color: #fff;
    }
    &:hover {
      td {
        background-color: rgb(245, 241, 241);
      }
    }
  }

  &__typeIcon {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    padding: 4px 4px;
    text-align: center;
    display: inline-block;
    line-height: 16px;
    img {
      vertical-align: top;
    }
  }
}

.cdx-block.image-tool .cdx-input.image-tool__caption {
  display: none !important;
}

.entf-editor {
  &__item {
    border: 1px dashed #ccc;
    padding: 20px;
    border-radius: 6px;
    margin-bottom: 20px;
  }
}

body.pdf-mode {
  background: #fff !important;
  padding-bottom: 0 !important;
  #wrapper {
    overflow: visible !important;
  }
  .container-fluid {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  #wrapper .navbar,
  #wrapper .topnav,
  #wrapper .footer {
    display: none !important;
  }
  #wrapper .content-page {
    padding: 0 !important;
    margin: 0 !important;
  }
}
.art-spdf {
  background-color: #fff;
  .art-spdf__editor .e-timeline-item--alert {
    margin-bottom: 20px;
  }
  &__header {
    img {
      width: 100%;
      height: auto;
    }
  }
  &__editor.schedule {
    page-break-inside: auto;
    padding-inline: 16px;
    margin-inline: auto;
    .ce-paragraph,
    .e-download,
    .ce-header {
      display: none !important;
    }
    .e-timeline-item {
      page-break-inside: avoid;
      page-break-after: auto;
    }
    .e-timeline-item__sectionName,
    .e-timeline-item__name,
    .e-timeline-item__time,
    .e-timeline-item__description,
    .e-timeline-item__alert {
      border: none !important;
    }
    .e-timeline__deleteBtn,
    .e-timeline__inject,
    .e-timeline__footer,
    .ce-toolbar__actions {
      display: none !important;
    }
    .e-timeline-item__time {
      right: 0;
      width: 200px;
      text-align: right;
    }
    .e-timeline-item__name {
      color: #2f2f2f !important;
      * {
        color: #2f2f2f !important;
      }
    }
    .e-timeline-item__description {
      margin-top: 0 !important;
      padding: 0 10px !important;
    }
    .e-timeline-itemContainer {
      padding-top: 10px;
      overflow: hidden;
    }
    .e-timeline-item--first::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 0;
      width: 30px;
      height: 24px;
      background: #fff;
    }
    .e-timeline-item--section.e-timeline-item--first::before {
      height: 34px;
    }
    .e-timeline-item--last::before {
      content: '';
      position: absolute;
      top: 40px;
      left: 0;
      width: 30px;
      height: 400px;
      background: #fff;
    }
    .e-timeline-item--section.e-timeline-item--last::before {
      height: 14px;
    }
    .e-timeline-item__sectionName {
      font-size: 22px !important;
    }
    .e-timeline > form > br {
      display: none !important;
    }
  }
  &__editor.article {
    page-break-inside: auto;
    padding-inline: 16px;
    margin-inline: auto;
  }
  .codex-editor__redactor {
    padding-bottom: 120px !important;
  }
  &__footer {
    background-color: #ffeef1;
    padding: 20px;
    text-align: center;
    page-break-inside: avoid;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    &Text {
      color: #999;
      display: block;
    }
    &Dots img {
      page-break-inside: avoid;
      display: block;
      position: absolute;
      bottom: 57px;
      right: 20px;
      width: 41.5px;
      height: 146px;
    }

    &Fireworks img {
      page-break-inside: avoid;
      display: block;
      position: absolute;
      width: 150px;
      right: 0;
      bottom: 250px;
    }
  }
}

.user-id-str {
  font-family: monospace;
  font-size: 12px;
  display: inline-block;
}

.user-purchase-log-data-display {
  width: 400px;
  height: 250px;
  background-color: #2f2f2f;
  color: #fff;
  font-family: monospace;
  font-size: 12px;
  line-height: 16px;
}

.popover {
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  max-width: 476px;
}

.profile-preview {
  margin-bottom: 30px;
  &__avatar {
    border-radius: 100px;
    width: 80px;
    height: 80px;
    border: 2px solid #eee;
    float: left;
    margin-right: 15px;
    background: no-repeat #fff;
    background-size: cover;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    &:hover {
      .profile-preview__edit-icon {
        display: block;
      }
    }
  }
  &__content {
    float: left;
  }
  &__edit-icon {
    position: absolute;
    top: 25px;
    left: 50%;
    margin-left: -10px;
    display: none;
    z-index: 10;
  }
  &__file-upload-mask {
    z-index: 11;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    font-size: 47px;
    opacity: 0;
  }
}
body.no-scroll {
  margin: 0;
  height: 100vh;
  overflow: hidden;
}
.dropdown-menu {
  max-height: 300px;
  overflow: auto;
}

.user-flag-toggle {
  display: flex;
  gap: 8px;

  &__label {
    width: max-content;
    display: grid;
    grid-template-columns: 16px 1fr;
    gap: 8px;
    cursor: pointer;

    &:has(.loading) {
      cursor: initial;
    }
  }
  input[type='checkbox'] {
    margin: 0;
    margin-top: 1px;
    position: relative;
    cursor: pointer;
  }
  &__text {
    width: max-content;
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
  }
  &__smile {
    display: inline-block;
    vertical-align: top;
    margin-top: 4px;
  }
  &__loader {
    margin-top: 1px;
    animation: rotate 2s infinite linear;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.toast-floating {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99999;
}

.sublog-extra-line {
  opacity: 0.6;
}

.stack-toggle-container {
  position: relative;
  overflow: visible;
}
.stack-toggle-button {
  position: absolute;
  top: 14px;
  right: 140px;
  z-index: 5;
}

.graph-view-switch-button-container {
  padding: 6px;
  background-color: #f8f9fa;
  width: max-content;
  border-radius: 0.3rem;
  margin-left: auto;
}

.graph-view-switch-button {
  border: none;
  outline: none;
  min-width: 72px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.7rem;
  margin-right: 6px;

  &[aria-selected='true'] {
    background-color: #484e57;
    color: #fafafa;
  }

  &:hover {
    background-color: rgba(#484e57, 0.2);

    &[aria-selected='true'] {
      background-color: #484e57;
      color: #fafafa;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

.publish-select-checkbox {
  margin: 3px;
  display: inline-block;
  vertical-align: 'top';
  position: static;
}

.codex-editor .ce-block h1 {
  font-size: 2em;
}
.codex-editor .ce-block h2 {
  font-size: 1.5em;
}
.codex-editor .ce-block h3 {
  font-size: 1.2em;
}

.codex-editor .ce-block h4 {
  font-size: 1.1em;
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}