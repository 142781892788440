.littleones-slider {
  .carousel-control-next {
    background-color: #cdcfe0;
    cursor: pointer;
    top: 0;
    right: 0;
    height: 47px;
    bottom: auto;
    border-radius: 3px;
    width: 47px;
  }
  &__item {
    height: 450px;
    padding: 0 0 0 0;
  }
}
