.mod-log-day-select {
  max-width: 300px;
  margin-bottom: 20px;
}
.mod-log {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  &__atTime {
    color: #666;
  }
  &__entryToggleIndicator {
    color: #666;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
  &__entry {
    position: relative;
    padding: 15px;
    border: 1px solid #ddd;
    border-bottom: none;
    background-color: #f7f7f7;
    &Header {
      color: #111;
      font-size: 1.1em;
    }
    &Summary {
      color: #666;
    }
    &--hoverable:hover {
      cursor: pointer;
      background-color: #fff;
    }
    .mod-log__entry {
      background-color: #fff;
      padding: 5px 10px;
    }
    &:first-of-type {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:last-of-type {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border-bottom: 1px solid #ddd;
    }
  }
  &__entriesBox {
    margin-top: 10px;
    padding: 5px;
    border-radius: 4px;
    background-color: #ddd;
  }
  &__entries {
    margin-top: 10px;
    .mod-log__entries {
      margin-top: 0;
      max-height: 300px;
      overflow: auto;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
    }
  }
}
