.vsnav {
  width: 100%;
  max-width: 300px;

  &__dropdown-menu {
    width: 100%;
  }

  .dropdown-item {
    padding: 0.35rem 13px;
  }

  &__toggle {
    width: 100%;
    text-align: left;
    background-color: #fff;
    border: 1px solid #c5c7cc;
    color: inherit;
    &:hover,
    &:active,
    &:focus {
      background-color: #f7f7f7 !important;
      color: inherit !important;
      border: 1px solid #c5c7cc !important;
      box-shadow: none !important;
    }
  }
  &.show .vsnav__toggle {
    background-color: #f7f7f7;
    color: inherit;
    border: 1px solid #f7f7f7;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05);
  }

  &__village {
    position: relative;
  }

  &__villageName {
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
    padding-top: 3px;
    font-weight: bold;
  }

  &__villageCount {
    float: right;
    margin-top: 3px;
    margin-left: 10px;
  }
}

.village-icon {
  width: 24px;
  height: 24px;
  border-radius: 3px;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  &__img {
    width: 16px;
    height: 16px;
    margin: 4px;
  }
}
