.like-toggle {
  cursor: pointer;
  &:hover {
    color: $danger;
  }
  &--liked {
    color: $danger;
  }
  &__text {
    text-decoration: underline;
    text-decoration-style: dotted;
  }
}
