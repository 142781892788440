.post-modal-curtain {
  &__bg {
    background-color: rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
  }
  &__curtain {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 20%;
    right: 0;
    bottom: 0;
    z-index: 201;
    box-shadow: -2px 0 10px 2px rgba(0, 0, 0, 0.2), -5px 0 30px 0 rgba(0, 0, 0, 0.2);
    border-left: 3px solid #eee;
    overflow: auto;
  }
  &__close {
    position: fixed;
    top: 36px;
    left: 20%;
    margin-left: -60px;
    color: #4b4b5a;
    display: inline-block;
    z-index: 300;
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 32px;
    padding: 4px;
    box-shadow: -2px 0 10px 2px rgba(0, 0, 0, 0.2), -5px 0 30px 0 rgba(0, 0, 0, 0.2);
    &:hover {
      color: #000;
    }
  }
  // .post-modal-curtain__bg, .post-modal-curtain__curtain, .post-modal-curtain__close {
  //   visibility: hidden;
  //   opacity: 0;
  //   transform: translateX(20px);
  // }
  // &-enter, &-exit-done {
  //   .post-modal-curtain__bg, .post-modal-curtain__curtain, .post-modal-curtain__close {
  //     visibility: hidden;
  //     opacity: 0;
  //     transform: translateX(20px);
  //   }
  //   .post-modal-curtain__bg {
  //     transform: none;
  //   }
  // }
  // &-enter-active {
  //   .post-modal-curtain__bg, .post-modal-curtain__curtain, .post-modal-curtain__close {
  //     visibility: visible;
  //     opacity: 1;
  //     transform: translateX(0);
  //   }
  //   .post-modal-curtain__bg {
  //     transform: none;
  //   }
  // }
  // &-enter-done, &-exit {
  //   .post-modal-curtain__bg, .post-modal-curtain__curtain, .post-modal-curtain__close {
  //     visibility: visible;
  //     opacity: 1;
  //     transform: translateX(0);
  //   }
  //   .post-modal-curtain__bg {
  //     transform: none;
  //   }
  // }
  // &-exit-active {
  //   .post-modal-curtain__bg, .post-modal-curtain__curtain, .post-modal-curtain__close {
  //     opacity: 0;
  //     transform: translateX(20px);
  //     visibility: hidden;
  //   }
  //   .post-modal-curtain__bg {
  //     transform: none;
  //   }
  // }
  &.open {
    opacity: 1;
    visibility: visible;
  }
  &.open .post-modal-curtain__curtain,
  &.open .post-modal-curtain__close {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
  &__side {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 33.3333%;
    background-color: #f9f9f9;
    overflow: auto;
    padding: 20px 5px;
    z-index: 20;
  }
  &__sideBox {
    margin-bottom: 20px;
    padding-bottom: 20px;
    margin-left: 10px;
    border-bottom: 2px solid #eee;
  }
  &__sideBoxTitle {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  &__commentsShadow {
    box-shadow: 0 -10px 10px -10px rgba(0, 0, 0, 0.2), 0 -10px 20px -20px rgba(0, 0, 0, 0.5);
  }
  &__reply {
    min-height: 140px;
    width: 100%;
  }
  &__replyFloat {
    min-height: 100px;
    position: fixed;
    bottom: 0;
    left: 20%;
    margin-left: 20px;
    border-top: 2px solid #eee;
    background-color: rgba(255, 255, 255, 0.9);
    padding-bottom: 20px;
  }
  &__inner {
    position: absolute;
    top: 0;
    right: 33.33333%;
    bottom: 0;
    width: 66.66666%;
    padding: 20px;
    z-index: 5;
    background: transparent;
    overflow: auto;
    &--maximized {
      width: 100% !important;
      right: 0 !important;
    }
  }
  &__hr {
    border-bottom: 2px solid #eee;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.post-comment-dd-menu {
  left: -105px !important; // override inline styles of reactstrap for dropdownMenu component
}

@include media-breakpoint-down(lg) {
  .post-modal-curtain__curtain {
    left: 10%;
  }
  .post-modal-curtain__close {
    left: 10%;
  }
  .post-modal-curtain__inner {
    right: 41.166666%;
    width: 58.833334%;
  }
  .post-modal-curtain__side {
    width: 41.166666%;
  }
  .post-modal-curtain__sideBox .card-body {
    padding: 0.5rem;
  }
  .mod-reply__input {
    max-height: 200px;
  }
  .post-modal-curtain__replyFloat {
    left: 10%;
    margin-left: 20px;
  }
}
