.village-status-tabs {
  &__nav-item {
    cursor: pointer;
    position: relative;
  }
  &__content {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &__closeSearch {
    margin-left: 8px;
    margin-top: 2px;
    vertical-align: top;
    &:hover {
      color: red;
    }
  }
}
