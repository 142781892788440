.post-comment {
  position: relative;
  padding-left: 40px;
  padding-right: 20px;
  padding-block: 8px;
  border-bottom: 1px solid #eee;
  position: relative;
  &:last-of-type {
    border-bottom: none;
  }
  &__avatar {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    left: 0;
    border-radius: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
  }
  &__summary {
    font-size: 12px;
  }
  &__author {
    font-weight: bold;
    display: inline-block;
    margin-right: 10px;
  }
  &__body {
    white-space: pre-wrap;
  }
  &__menu {
    position: absolute;
    top: 10px;
    right: 0;
  }

  &__bar {
    color: #a7a7a7;
    font-size: 14px;
    &Likes {
      display: inline-block;
      vertical-align: top;
      line-height: 16px;
      margin-top: 10px;
      margin-right: 10px;
      color: #a7a7a7;
    }
  }

  &__author-signature {
    margin-top: 12px;
    padding-top: 6px;
    border-top: 1px solid #eee;

    p {
      margin: 0;
    }
  }

  a.ifAdmin {
    color: inherit;
    text-decoration: underline !important;
    text-decoration-style: dotted !important;
  }
}
