//
// formm-wizard.scss
//

.sw-main {
  box-shadow: none;
  border: 1px solid darken($gray-300, 2%);

  .sw-toolbar {
    background: transparent;
    padding: 10px;
  }
  .step-content {
    background-color: transparent !important;
  }
}

// sw-theme-default

.sw-theme-default {
  .step-content {
    background-color: transparent;
  }

  > ul.step-anchor {
    > li {
      > a {
        color: $gray-600;
        &:hover {
          color: $gray-600;
        }
        &:after {
          background: $primary;
        }
      }
      &.active {
        > a {
          color: $primary !important;
        }
      }
      &.done {
        > a {
          color: $gray-600 !important;
          &:after {
            background: $success;
          }
        }
      }
    }
  }
}

// sw-theme-arrows

.sw-theme-arrows {
  > ul.step-anchor {
    background: $gray-300;
    border-bottom-color: $gray-300;

    > li {
      > a,
      > a:hover {
        background: $gray-300;
        color: $gray-600;
      }

      > a {
        &::after {
          border-left-color: $gray-300 !important;
        }
        &::before {
          border-left-color: $gray-400 !important;
        }
      }
      &.active {
        > a {
          background: $primary !important;
          border-color: $primary !important;
          &:after {
            border-left-color: $primary !important;
          }
        }
      }

      &.done {
        > a {
          background: lighten($primary, 24%) !important;
          border-color: lighten($primary, 24%) !important;
          &:after {
            border-left-color: lighten($primary, 24%) !important;
          }
          &::before {
            border-left-color: $primary !important;
          }
        }
      }
    }
  }
}

// sw-theme-circles

.sw-theme-circles {
  > ul.step-anchor {
    margin-top: 30px;
    background-color: transparent;
    &:before {
      background-color: $gray-300;
    }
    > li {
      > a {
        font-size: 14px;
        width: 40px;
        height: 40px;
        padding: 12px 0;
        background-color: $gray-300;
        border-color: $gray-200 !important;
        box-shadow: none !important;
        &:hover {
          background-color: $gray-300;
        }

        small {
          bottom: -20px;
          width: 100%;
        }
      }

      > a,
      > a:hover {
        color: $gray-600;
        small {
          color: $gray-600;
        }
      }
      &.active {
        > a {
          background: $primary !important;
          border-color: $primary !important;
          color: $white !important;
          > small {
            color: $primary;
          }
        }
      }

      &.done {
        > a {
          background: $success !important;
          border-color: $success !important;
          color: $white !important;
          > small {
            color: $success;
          }
        }
      }
    }
  }
}

// sw-theme-dots

.sw-theme-dots {
  > ul.step-anchor {
    background-color: transparent;
    &:before {
      background-color: $gray-300;
    }
    > li {
      > a {
        &:before {
          bottom: -6px;
          background: $gray-300;
        }

        &:after {
          left: 45%;
          bottom: -8px;
          background: $gray-400;
        }
      }
      > a,
      > a:hover,
      > a:focus {
        color: $gray-600;
      }
      &.active {
        > a {
          color: $primary;
          &:after {
            background: $primary;
          }
        }
      }
      &.done {
        > a {
          color: $success;
          &:after {
            background: $success;
          }
        }
      }
    }
  }
}

// responsive

@media (max-width: 768px) {
  .sw-main {
    > ul.step-anchor {
      > li {
        display: block;
        width: 100%;
      }
    }
  }
  .sw-theme-circles {
    > ul.step-anchor {
      > li > a {
        > small {
          bottom: 14px;
          left: 76px;
        }
      }
    }
  }
  .sw-theme-dots {
    > ul.step-anchor {
      > li {
        > a:after {
          top: -36px;
          left: -31px !important;
        }
      }
    }
  }
}
