.mod-display {
  max-width: 120px;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  &__name {
    white-space: pre;
  }
  &__circle {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 7px;
    margin-right: 5px;
    position: relative;
    &--online {
      background-color: $success;
    }
    &--offline {
      background-color: #aaa;
    }
    &--absent {
      background-color: transparent;
    }
    svg {
      position: absolute;
      top: 0;
      left: 0;
      color: #333;
    }
  }
}
