.publishcontent-component {
  .unpublished-delete-td {
    width: 20px;

    > .unpublished-delete-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}
