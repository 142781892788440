@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@font-face {
  font-family: 'Brightwall';
  src: url('/fonts/Brightwall Regular.otf') format('opentype'), url('/fonts/Brightwall Regular.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brightwall';
  src: url('/fonts/Brightwall Italic.otf') format('opentype'), url('/fonts/Brightwall Italic.woff') format('woff');
  font-style: italic;
  font-weight: normal;
  font-display: swap;
}