.mod-reply {
  &__comment {
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 10px 60px 10px 60px;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 7px;
    min-height: 60px;
  }
  &__input {
    border: 1px solid #eee;
    border-radius: 5px;
    color: inherit;
    width: 100%;
    outline: none;
    box-shadow: none;
    max-height: 300px;
  }
  &__avatar {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    position: absolute;
    top: 15px;
    left: 15px;
  }
  &__send {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0;

    &:disabled {
      cursor: not-allowed;
    }
  }
  &__extras {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    &Group {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: left;
      margin: 0;
      margin-right: 20px;
      vertical-align: top;
    }
    &Label {
      display: flex;
      align-items: center;
      justify-content: left;
      margin: 0;
    }
    &Input {
      padding-left: 10px;
      display: flex;
      align-items: left;
      justify-content: left;
    }
  }
}

@include media-breakpoint-down(md) {
  .mod-reply__extrasGroup:first-of-type {
    margin-bottom: 10px;
  }
}
