.post {
  position: relative;
  &__title {
    margin: 0 0 5px 0;
    padding: 0;
    font-size: 20px;
    font-weight: bold;
    width: 90%;
  }
  &__menu {
    position: absolute;
    top: 0;
    right: 0;
  }
  &__body {
    white-space: pre-wrap;
  }
  &__ambit {
    margin-top: 10px;
    position: relative;
  }

  > .post__menu {
    > .btn-group {
      > .dropdown-menu {
        left: -81px !important;
      }
    }
  }
}

.post-summary {
  font-size: 12px;
  margin-bottom: 20px;
  &__village {
    display: inline-block;
    margin-right: 5px;
  }
  &__author {
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
  }
  &__posted {
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
  }
  &__counter {
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;

    &Comments {
      &--commented {
        color: $danger;
      }
    }
  }
  a.ifAdmin {
    color: inherit;
    text-decoration: underline !important;
    text-decoration-style: dotted !important;
  }
}

@include media-breakpoint-down(md) {
  .post-summary__villageContainer {
    display: block;
  }
  .post-summary__firstBull {
    display: none;
  }
}
