.author-display {
  text-align: left;
  &__avatar {
    width: 64px;
    height: 64px;
    border-radius: 200px;
    margin-right: 20px;
  }
  &__name {
    font-weight: bold;
  }
}
