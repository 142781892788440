.summary-title {
  font-weight: 900;
  font-size: 20px;

  td {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.summary-component {
  display: flex;

  > .summary-column {
    flex: 1;

    &:not(:last-of-type) {
      margin-right: 15px;
    }
  }
  .card-box {
    padding: 15px;
  }

  .summary-content {
    padding: 12px;

    > .roles-items {
      display: flex;

      > :not(:last-child) {
        margin-right: 9px;
      }
    }
  }

  td {
    border: none;
  }
}
.user-display {
  position: relative;
  padding: 0 30px 0 50px;
  &__avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__name {
    font-weight: bold;
    display: block;
    font-size: 16px;
    line-height: 1;
    padding-top: 2px;
    margin-bottom: 2px;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  &__email {
    display: block;
    font-size: 14px;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  &__menu {
    position: absolute;
    top: 0;
    right: 0;
  }
  a.ifAdmin {
    color: inherit;
    text-decoration: underline !important;
    text-decoration-style: dotted !important;
  }
}
